import { createStore } from 'vuex'

export default createStore({

    state: {
        userName: "",
        token: "",
        workInLocalNetwork: false,
        workInTestBase: false,
        selectedCameraDeviceId: "",
        skladNum: 0,
        assemblyRolls: [],
        assemblyName: '',
        assemblyType: ''
    },

    getters: {
        isAuthorized(state) {
            return (state.userName !== '');
        },

        assemblyRolls(state) {
            return state.assemblyRolls;
        },

        assemblyName(state) {
            return state.assemblyName;
        },

        assemblyType(state) {
            return state.assemblyType;
        },
    },

    mutations: {
        setUserName(state, userName) {
            state.userName = userName;
        },

        setToken(state, token) {
            state.token = token;
        },

        setSkladNum(state, skladNum) {
            state.skladNum = skladNum;
        },

        setWorkInLocalNetwork(state, workInLocalNetwork) {
            state.workInLocalNetwork = workInLocalNetwork;
        },

        setWorkInTestBase(state, workInTestBase) {
            state.workInTestBase = workInTestBase;
        },

        setSelectedCameraDeviceId(state, selectedCameraDeviceId) {
            state.selectedCameraDeviceId = selectedCameraDeviceId;
        },

        setAssemblyRolls(state, assemblyRolls) {
            state.assemblyRolls = assemblyRolls;
        },

        setAssemblyName(state, assemblyName) {
            state.assemblyName = assemblyName;
        },

        setAssemblyType(state, assemblyType) {
            state.assemblyType = assemblyType;
        },
    },

    actions: {
        login({ commit, state, dispatch }, { userName, password }) {

            var Base64 = { _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) { var t = ""; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r = c1 = c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } };

            commit('setUserName', userName);
            commit('setToken', Base64.encode(state.userName + ":" + password));

            if (userName !== 'Тарасов Дмитрий') {
                commit('setWorkInTestBase', false);
            }

            dispatch('saveToLocalSorage');
        },

        logout({ state, commit, dispatch }) {
            commit('setUserName', "");
            commit('setToken', "");

            if (state.userName !== 'Тарасов Дмитрий') {
                commit('setWorkInTestBase', false);
            }

            dispatch('saveToLocalSorage');
        },

        setSkladNum({ commit, dispatch }, skladNum) {
            commit('setSkladNum', skladNum);
            dispatch('saveToLocalSorage');
        },

        setWorkInLocalNetwork({ commit, dispatch }, workInLocalNetwork) {
            commit('setWorkInLocalNetwork', workInLocalNetwork);
            dispatch('saveToLocalSorage');
        },

        setWorkInTestBase({ commit, dispatch }, workInTestBase) {
            commit('setWorkInTestBase', workInTestBase);
            dispatch('saveToLocalSorage');
        },

        setSelectedCameraDeviceId({ commit, dispatch }, selectedCameraDeviceId) {
            commit('setSelectedCameraDeviceId', selectedCameraDeviceId);
            dispatch('saveToLocalSorage');
        },

        setAssemblyRolls({ commit, dispatch }, assemblyRolls) {
            commit('setAssemblyRolls', assemblyRolls);
            dispatch('saveToLocalSorage');
        },

        setAssemblyName({ commit, dispatch }, assemblyName) {
            commit('setAssemblyName', assemblyName);
            dispatch('saveToLocalSorage');
        },

        setAssemblyType({ commit, dispatch }, assemblyType) {
            commit('setAssemblyType', assemblyType);
            dispatch('saveToLocalSorage');
        },

        addRollToAssemblyRolls({ state, commit, dispatch }, roll) {
            const rolls = state.assemblyRolls;

            const found = rolls.some(item => item.code === roll.code);
            if (!found) {
                rolls.push(roll);
            };
            commit('setAssemblyRolls', rolls);
            dispatch('saveToLocalSorage');
        },

        removeRollFromAssemblyRolls({ state, commit, dispatch }, roll) {
            const rolls = state.assemblyRolls.filter(item => item.code != roll.code);
            commit('setAssemblyRolls', rolls);
            dispatch('saveToLocalSorage');
        },

        clearAssemblyRolls({ commit, dispatch }) {
            commit('setAssemblyRolls', []);
            dispatch('saveToLocalSorage');
        },

        putAssemblyRollsTo1C({ commit, dispatch }) {

        },

        initStore({ state, commit }) {
            if (localStorage.userName) {
                commit('setUserName', localStorage.userName);
            }

            if (localStorage.token) {
                commit('setToken', localStorage.token);
            }

            if (localStorage.skladNum) {
                commit('setSkladNum', localStorage.skladNum);
            }

            if (localStorage.workInLocalNetwork) {
                commit('setWorkInLocalNetwork', JSON.parse(localStorage.workInLocalNetwork));
            }

            if (state.userName !== 'Тарасов Дмитрий') {
                commit('setWorkInTestBase', false);
            }
            else if (localStorage.workInTestBase) {
                commit('setWorkInTestBase', JSON.parse(localStorage.workInTestBase));
            }

            if (localStorage.selectedCameraDeviceId) {
                commit('setSelectedCameraDeviceId', localStorage.selectedCameraDeviceId);
            }
            
            if (localStorage.assemblyRolls) {
                try {
                    commit('setAssemblyRolls', JSON.parse(localStorage.assemblyRolls));
                } catch {
                    commit('setAssemblyRolls', []);
                }
            } else {
                
            }

            if (localStorage.assemblyName) {
                commit('setAssemblyName', localStorage.assemblyName);
            }

            if (localStorage.assemblyType) {
                commit('setAssemblyType', localStorage.assemblyType);
            }

        },

        saveToLocalSorage({ state }) {
            localStorage.userName = state.userName;
            localStorage.token = state.token;
            localStorage.skladNum = state.skladNum;
            localStorage.workInLocalNetwork = state.workInLocalNetwork;
            localStorage.workInTestBase = state.workInTestBase;
            localStorage.selectedCameraDeviceId = state.selectedCameraDeviceId;
            localStorage.assemblyRolls = JSON.stringify(state.assemblyRolls);
            localStorage.assemblyName = state.assemblyName;
            localStorage.assemblyType = state.assemblyType;
        }
    },
})