<template>
  <div class="wrapper">
    <div>
      <button class="btn btn-light" :class="{ active: mode == 'scanner' }" @click="setModeScanner">
        <img src="../assets/shtrihcode32.png" />
      </button>
      &nbsp;
      <button class="btn btn-light" :class="{ active: mode == 'keyboard' }" @click="setModeKeyboard">
        <img src="../assets/numbers32.png" />
      </button>
    </div>

    <video v-show="mode == 'scanner'" class="video-window" id="video"></video>
    <div v-if="mode == 'keyboard'">
      <input class="input_shtrihcode" type="number" v-model="shtrihkod">
      <div>
        <button class="btn btn-primary" @click="findShtrihcodeClick"><img src="../assets/search24.png"></button>
      </div>
    </div>
  </div>
</template>
  
<script>

import * as ZXing from "../barcode-scan/index.min.js";

export default {

  name: 'ScanCode',

  emits: ['onCode', 'onError', 'onClear'],
  props: {
    cameraId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      codeReader: "",
      mode: 'scanner',
      shtrihkod: '',
    };
  },

  watch: {

  },

  methods: {
    setModeKeyboard() {
      this.clearFoundedRoll();
      this.shtrihkod = '';
      this.mode = 'keyboard';

    },

    setModeScanner() {
      this.clearFoundedRoll();
      this.mode = 'scanner';
    },

    startCaptureVideo() {
      this.codeReader.decodeFromVideoDevice(
        this.cameraId,
        "video",
        (result, err) => {
          if (result && this.mode == 'scanner') {
            this.$emit('onCode', result.text);
          }
          if (err && !(err instanceof ZXing.NotFoundException)) {
            this.$emit('onError', err);
          }
        }
      );
    },

    refreshVideo() {
      this.codeReader.reset();
      this.startCaptureVideo();
    },

    stopCaptureVideo() {
      this.codeReader.reset();
    },

    findShtrihcodeClick() {
      const len = this.shtrihkod.toString().length;
      if (len == 13) {
        this.$emit('onCode', this.shtrihkod);
      }
    },

    clearFoundedRoll() {
      this.$emit('onClear');
    },

  },

  mounted() {
    this.codeReader = new ZXing.BrowserMultiFormatReader();
    this.startCaptureVideo();
  },

  unmounted() {
    this.stopCaptureVideo();
  },

};
</script>
  
<style>
.wrapper {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5px;
  max-width: 500px;
  width: 80%;

}

.video-window {  
  height: 160px;  
  margin: 5px;
}

.input_shtrihcode {
  padding: 5px;
  margin: 5px;
  font-size: smaller;
}

video {
  -webkit-transform: scaleX(1.75);
  transform: scaleX(1.75);  
}

</style>