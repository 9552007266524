<template>
  <div class="div-title">Проверка ролика</div>
  <div>
    <scan-roll class="scan-roll-wrapper" @onCode="onCode" @onRoll="onRoll" @onRequesting="onRequesting" @onError="onError" @onClear="onClear"
      :cameraId="$store.state.selectedCameraDeviceId">
    </scan-roll>
  </div>

  <div v-if="code != ''"><img src="../assets/shtrihcode32.png" /> <strong> {{ code }} </strong></div>

  <div v-if="inRequesting">Поиск ролика...</div>

  <div v-if="error_text != ''"> <strong>{{ error_text }}</strong></div>

  <div class="grid-wrapper" v-if="!inRequesting && roll_code != ''">
    <div class="rollinfo_grid">

      <!-- плотность (факт) -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Пл. (факт), г/м2
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.gr }}</strong>
      </div>

      <!-- плотность (для этикетки) -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Пл. (д/этикетки), г/м2
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.gr2 }}</strong>
      </div>

      <!-- формат -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Ширина рулона, мм
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.format }}</strong>
      </div>

      <!-- вес ролика -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Масса нетто, кг
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.count_kg }}</strong>
      </div>

      <!-- номер рулона -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        № рулона
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.roll_number }}</strong>
      </div>

      <!-- дата выпуска -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Дата выпуска
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ dateToStringDDMMYYYY(roll.date_release) }}</strong>
      </div>

      <!-- бригада -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Бригада
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.brigade_num }}</strong>
      </div>

      <!-- марка -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Марка
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.marka }}</strong>
      </div>

      <!-- место хранения -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        Место хранения
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <strong>{{ roll.place }}</strong>
      </div>

      <!-- некондиция -->
      <div class="rollinfo_grid_item rollinfo_grid_item_title">
        
      </div>

      <div class="rollinfo_grid_item rollinfo_grid_item_value">
        <p class="red_text"><strong>{{ roll.nekond }}</strong> </p>
      </div>

    </div>
  </div>

  <!-- история -->
  <div class="grid-wrapper" v-if="!inRequesting && roll_code != '' && history.length != 0">
    <div class="rollhistory_grid">

      <div class="rollhistory_grid_item rollhistory_grid_item_title">
        Дата, время
      </div>

      <div class="rollhistory_grid_item rollhistory_grid_item_title">
        Пользователь
      </div>

      <div class="rollhistory_grid_item rollhistory_grid_item_title">
        Описание
      </div>

      <template v-for="history_record in history" :key="history_record.num">

        <div class="rollhistory_grid_item rollhistory_grid_item_value">
          <strong>{{ dateToStringDDMMYYYY_HHMMSS(history_record.datetime) }}</strong>
        </div>

        <div class="rollhistory_grid_item rollhistory_grid_item_value">
          <strong>{{ history_record.user }}</strong>
        </div>

        <div class="rollhistory_grid_item rollhistory_grid_item_value">
          <strong>{{ history_record.description }}</strong>
        </div>
  
      </template>
    </div>

  </div>
</template>
  
<script>

import ScanRoll from "../components/ScanRoll.vue";

export default {
  components: {
    ScanRoll,
  },

  data() {
    return {
      code: '',
      inRequesting: false,
      roll_code: '',
      error_text: '',
      roll: {},
      history: [],
    };
  },

  methods: {
    onCode(code) {
      this.code = code;
    },

    onRoll(roll, history) {
      this.error_text = '';
      this.inRequesting = false;
      this.roll_code = roll.code;
      this.roll = roll;
      this.history = history;
    },

    onError(error_text) {
      this.error_text = error_text;
      this.inRequesting = false;
      this.roll_code = '';
      this.roll = {};
      this.history = [];
    },

    onRequesting() {
      this.inRequesting = true;
    },

    onClear() {
      this.error_text = '';
      this.inRequesting = false;
      this.roll_code = '';
      this.roll = {};
      this.code = '';
      this.history = [];
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    dateToStringDDMMYYYY_HHMMSS(date) {

      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4) + 
        " " +

        date.substring(11, 13) +
        ":" +
        date.substring(14, 16) +
        ":" +
        date.substring(17, 19)
      );
    },
  },

  mounted() {

  },
};
</script>  

<style scoped>
.scan-roll-wrapper {
  margin: 5px auto;
}

.grid-wrapper {
  margin: 10px 10px 0 10px;
}

.rollinfo_grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
  border-color: lightgrey;
  border-top: 1px solid;
  border-left: 1px solid;
}

.rollinfo_grid_item {
  padding: 5px;
  border-color: lightgrey;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.rollinfo_grid_item_title {
  text-align: end;
}

.rollinfo_grid_item_value {
  text-align: start;
}

.rollhistory_grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 800px;
  border-color: lightgrey;
  border-top: 1px solid;
  border-left: 1px solid;
}

.rollhistory_grid_item {
  padding: 5px;
  border-color: lightgrey;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.rollhistory_grid_item_title {
  text-align: center;
}

.rollhistory_grid_item_value {
  text-align: center;
}

.div-title {
  font-size: large;
  padding: 5px;
  background-color:darkseagreen;
  margin-bottom: 5px;
}
.red_text {
  color: red;
}

</style>