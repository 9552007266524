<template>
  <section class="vh-9">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col col-xl-8">
          <div class="card" style="border-radius: 1rem">
            <div class="row g-0 justify-content-center align-items-center">
              <div class="col-md-6 col-lg-5 d-none d-md-block">
                <img
                  src="../assets/logo180.png"
                  alt="login form"
                  class="img-fluid"
                  style="border-radius: 1rem 0 0 1rem"
                />
              </div>

              <div class="col-md-6 col-lg-7 d-flex align-items-center">
                <div class="card-body p-4 p-lg-5 text-black">
                  <form>
                    <h2 class="fw-normal mb-3 pb-3" style="letter-spacing: 1px">
                      Вход
                    </h2>

                    <div class="form-outline mb-4">
                      <label class="form-label" for="inputUserName">Имя:</label>
                      <input
                        :disabled="$store.getters.isAuthorized"
                        id="inputUserName"
                        type="text"
                        v-model="userName"
                        class="form-control form-control-lg"
                      />
                    </div>

                    <div class="form-outline mb-4">
                      <label class="form-label" for="inputPassword"
                        >Пароль:</label
                      >
                      <input
                        :disabled="$store.getters.isAuthorized"
                        id="inputPassword"
                        type="password"
                        v-model="password"
                        class="form-control form-control-lg"
                      />
                    </div>

                    <div class="pt-1 mb-4">
                      <button
                        v-if="!$store.getters.isAuthorized"
                        class="btn btn-dark btn-lg btn-block m2"
                        type="button"
                        @click="login"
                      >
                        Войти
                      </button>                      
                      <button
                        v-if="$store.getters.isAuthorized"
                        class="btn btn-warning btn-lg btn-block m2"
                        type="button"
                        @click="logout"
                      >
                        Выйти из системы
                      </button>
                      <button
                        class="btn btn-dark btn-lg btn-block m2"
                        type="button"
                        @click="$router.go(-1)"
                      >
                        Вернуться назад
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
     
<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
    };
  },
  
  methods: {
    refreshPage() {
      this.userName = this.$store.state.userName;
      this.password = this.$store.getters.isAuthorized ? "********" : this.password;
    },

    login() {      
      this.$store.dispatch('login', {userName: 
        this.userName, password: this.password
      });
      this.refreshPage();      
      this.$router.go(-1);
    },

    logout() {
      this.$store.dispatch('logout');
      this.password = "";
      this.refreshPage();
    },
  },

  mounted() {
    this.refreshPage();
  },

};
</script>

<style scoped>
h1 {
  margin-top: 50px;
}

.m2 {
  margin: 2px;
  border: 1px;
  border-style: solid;
}
</style>
    
