import { createRouter, createWebHashHistory } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import HomePage from '../views/HomePage.vue'
import RollInfoPage from '../views/RollInfoPage.vue'
import AssemblyPage from '../views/AssemblyPage.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/rollinfo',
    name: 'rollinfo',
    component: RollInfoPage
  },
  {
    path: '/assembly',
    name: 'assembly',
    component: AssemblyPage
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
