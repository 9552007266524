<template>
    <h1>Программа учета склада</h1>
    <h2>(c) ООО "АКЗ", 2024</h2>
    <h2>v.2024.03.28.1</h2>
    
    <div class="mainmenu">
        <router-link class="menu-link" to="/rollinfo">
            <div class="menu-item mainmenu-button">Информация о ролике</div>
        </router-link>
        <router-link class="menu-link" to="/assembly">
            <div class="menu-item mainmenu-button">Сборка</div>
        </router-link>
    </div>
</template>

<script>

export default {
};
</script>

<style scoped>

h1 {
    margin-top: 50px;
}
.menu-item {
    border: solid 2px;
    border-radius: 10px;
    margin: 5px 50px;
    padding: 20px;
    border-color: gray;
    background-color: darkseagreen;
}

.menu-link {
    text-decoration: none;
    color: black;
}

.mainmenu {
    margin-top: 30px;    
}

.mainmenu-button {        
    font-size: x-large;
}

</style>
