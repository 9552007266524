<template>
  <scan-code :cameraId="cameraId" @onCode="onCode" @onError="onError" @onClear="onClear">
  </scan-code>
</template>
  
<script>

import ScanCode from "./ScanCode.vue";
import { fetchRoll } from "../api.js";
import beepFile from '../assets/beep.mp3';

export default {

  components: {
    ScanCode
  },

  name: 'ScanRoll',
  emits: ['onCode', 'onRoll', 'onRequesting', 'onError', 'onClear'],
  props: {
    cameraId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      lastCode: '',
      lastBeep: Date.now()-5000,
      minIntervalMS: 3000,
    }
  },

  methods: {
    async onCode(code) {
      if (code != this.lastCode || this.diffTime(Date.now(), this.lastBeep) > this.minIntervalMS)  {      
        await this.beep();
        this.lastCode = code;
        this.lastBeep = Date.now();        
        this.$emit('onCode', code);
        this.$emit('onRoll', {});
        this.$emit('onRequesting');
        this.findRoll(code);        
      }

    },

    onError(error) {
      this.lastCode = '';
      this.$emit('onError', error);
    },

    onClear() {
      this.lastCode = '';
      this.$emit('onClear');
    },

    async beep() {
      var audio = new Audio(beepFile);
      audio.play();
    },

    diffTime(date2, date1) {
      return date2 - date1;
    },

    async findRoll(code) {
      const fetchResult = await fetchRoll(code);
      if (fetchResult.errorText != "") {
        this.$emit('onError', fetchResult.errorText);
      } else {
        if (fetchResult.data.rolls && fetchResult.data.rolls.length > 0) {
          this.$emit('onRoll', fetchResult.data.rolls[0], fetchResult.data.history);
        } else {
          this.$emit('onError', 'Ролик не найден');
        }
      }
    },
  },

  mounted() {

  },

  unmounted() {

  },
}

</script>
  
<style></style>