<template>
  <nav class="navbar navbar-expand-md bg-body-tertiary" style="background-color: #e3f2fd">
    <div class="container-fluid">
      <button class="backbutton" type="button" @click="$router.go(-1)">
        <img src="../assets/back32.png" alt="Вернуться назад" width="28" height="28" />
      </button>

      &nbsp;
      
      <a class="navbar-brand" href="#">&nbsp;"Склад"</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item nav-item_mg">
            <router-link class="dropdown-item menu-item" to="/test">Тест</router-link>
          </li>
        </ul> -->
       
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="modal" data-bs-target="#modalPreference">
              <img src="../assets/preference24.png" />
            </a>
          </li>

          <li v-if="$store.getters.isAuthorized" class="nav-item">
            <router-link class="nav-link" to="/login"><img src="../assets/user24.png" />&nbsp;<strong>{{
              $store.state.userName
            }}</strong></router-link>
          </li>

          <li v-if="!$store.getters.isAuthorized" class="nav-item">
            <router-link class="nav-link" to="/login"><img
                src="../assets/user24.png" />&nbsp;<strong>Вход</strong></router-link>
          </li>
        </ul>

      </div>
    </div>
  </nav>

  <!-- modal window with preferences -->
  <div class="modal fade" id="modalPreference" tabindex="-1" aria-labelledby="modalPreferenceLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Настройки</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          
          <div class="bordered-div">
            <label class="form-check-label" for="cbWorkInLocalNetwork">
              <input class="form-check-input" type="checkbox" v-model="workInLocalNetwork" id="cbWorkInLocalNetwork" />
              Работать в локальной сети
            </label>
          </div>

          <div class="bordered-div">
            <label class="form-check-label" for="cbWorkInTestBase">
              <input :disabled="$store.state.userName != 'Тарасов Дмитрий'" class="form-check-input" type="checkbox"
                v-model="workInTestBase" id="cbWorkInTestBase" />

              Работать в тестовой базе
            </label>
          </div>

          <div class="bordered-div" id="skalNumPanel" style="margin; 10px">
            <label for="inputSkladNum">Номер склада:</label>
            <input type="number" id="inputSkladNum" @input="skladNumOnChange" v-model="skladNum"/>
          </div>

          <div class="bordered-div" id="sourceCameraSelectPanel">
            <label for="sourceCameraSelect">Камера для сканирования:</label>
            <select id="sourceCameraSelect" v-on:change="sourceSelectChange" :value="sourceCameraSelectId"></select>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ZXing from "../barcode-scan/index.min.js";

export default {
  data() {
    return {
      workInLocalNetwork: true,
      workInTestBase: true,
      sourceCameraSelectId: '',
      skladNum: 0,
    };
  },

  methods: {
    workInLocalNetworkWatcher(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("setWorkInLocalNetwork", newValue);
      }
    },

    workInTestBaseWatcher(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("setWorkInTestBase", newValue);
      }
    },
    wrapNavigate(navigate, event) {
      document
        .getElementById("navbarSupportedContent")
        .classList.remove("show");
      navigate(event);
    },

    sourceSelectChange() {     
      this.$store.dispatch("setSelectedCameraDeviceId", document.getElementById("sourceCameraSelect").value);
    },

    skladNumOnChange() {
      console.log(this.skladNum);
      this.$store.dispatch("setSkladNum", this.skladNum);
    }

  },

  mounted() {
    this.workInLocalNetwork = this.$store.state.workInLocalNetwork;
    this.$watch("workInLocalNetwork", this.workInLocalNetworkWatcher);

    this.workInTestBase = this.$store.state.workInTestBase;
    this.$watch("workInTestBase", this.workInTestBaseWatcher);

    this.skladNum = this.$store.state.skladNum;

    const codeReader = new ZXing.BrowserMultiFormatReader();
    codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        const sourceSelect = document.getElementById("sourceCameraSelect");

        if (videoInputDevices.length >= 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = document.createElement("option");
            sourceOption.text = element.label;
            sourceOption.value = element.deviceId;
            sourceSelect.appendChild(sourceOption);
          });          
        }

        if (this.$store.state.selectedCameraDeviceId == '') {
          this.$store.dispatch("setSelectedCameraDeviceId", videoInputDevices[0].deviceId);
        }
        else {
          this.sourceCameraSelectId = this.$store.state.selectedCameraDeviceId;          
        }

      })
      .catch((err) => {
        console.error(err);
        this.$store.dispatch("setSelectedCameraDeviceId", "");
      });
  },
};
</script>

<style scoped>
.menu-item {
  border: 2px solid #707070;
  border-radius: 7px;
  padding: 5px;
  margin: 2px;
}

.nav-item_mg {
  margin: 0px 2px;
}

.submenu {
  border: 2px solid #707070;
  border-radius: 7px;
  padding: 5px;
  margin: 2px;
}

.backbutton {
  border: none;
  /* margin: 0 10px 0 0; */
  padding: 0px;
  background-color: transparent;
}

.bordered-div {
  border-top: 1px dashed;
  padding: 10px;  
  border-color: lightgrey;
}

</style>