const URL_HS_Web = 'https://sklad.armkz.ru/upp/hs/api/';
const URL_HS_Local = 'https://10.80.80.3/upp/hs/api/';

const URL_HS_Web_Test = 'https://sklad.armkz.ru/upp_copy2/hs/api/';
const URL_HS_Local_Test = 'https://10.80.80.3/upp_copy2/hs/api/';

import axios from 'axios'
import store from './store'

// common functions
function getUrlHttpService() {
    return store.state.workInTestBase ?
        (store.state.workInLocalNetwork === true ? URL_HS_Local_Test : URL_HS_Web_Test) :
        (store.state.workInLocalNetwork === true ? URL_HS_Local : URL_HS_Web);
}

function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export async function fetchRoll(code) {

    const url = `${getUrlHttpService()}doc_roll?code=${code}`;
    const authorizationString = "Basic " + store.state.token;

    const defaultOptions = {
        headers: {
            'Authorization': authorizationString,
            'Content-Type': 'text/plain',
        },
    };

    return await axios.get(url, defaultOptions).then(response => {
        return {
            data: response.data, errorText: "",
        }
    }).catch(err => {        
        return {
            data: [],            
            errorText: !err.response ? "Нет связи с 1С" : (err.response.status === 401 ? "Нет доступа" : err.response.statusText),
        }
    });
}

export async function fetchPutAssemblyRollsTo1C(skladNum, assemblyName, assemblyType, rolls) {
    const url = `${getUrlHttpService()}assembly_put_assembly`;
    const authorizationString = "Basic " + store.state.token;

    const defaultOptions = {
        headers: {
            'Authorization': authorizationString,
            'Content-Type': 'text/plain',
        },
    };
    
    const jsonData = {
        "skladNum": skladNum,
        "assemblyName": assemblyName,
        "assemblyType": assemblyType,
        "rolls": rolls
    };

    return await axios.post(url, jsonData, defaultOptions).then(response => {
        return {
            bool_result: true, errorText: "", doc_num: response.data.doc_num,
        }
    }).catch(err => {
        return {
            bool_result: false, 
            errorText: !err.response ? "Нет связи с 1С" : (err.response.status === 401 ? "Нет доступа" : err.response.statusText),            
        }
    });
}
