<template>
  <div class="div-title">Сборка роликов</div>

  <div id="liveAlertPlaceholder"></div>

  <div v-if="puttinTo1CinProgress" class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>

  <div class="modal fade" id="confirmationClearAssemblyRolls" tabindex="-1"
    aria-labelledby="confirmationClearAssemblyRollsLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmationClearAssemblyRollsLabel">Подтверждение</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <div>
            Внимание! Будут очищены все ролики из сборки.
          </div>
          <div>
            Очистить сборку?
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="clearAssemblyRolls()">Да, очистить
            сборку</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="confirmationPutAssemblyTo1C" tabindex="-1"
    aria-labelledby="confirmationClearAssemblyRollsLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmationPutAssemblyTo1CLabel">Подтверждение</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <div>
            Внимание! В 1С будет создан документ "Сборка роликов" с наименованием "{{ assemblyName }}"
          </div>
          <div>
            Продолжить?
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="putAssemblyRollsTo1C()">Да,
            создать сборку в 1С</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
        </div>
      </div>
    </div>
  </div>

  <div class="scan-and-info">
    <scan-roll class="scan-roll-wrapper" @onCode="onCode" @onRoll="onRoll" @onRequesting="onRequesting" @onError="onError"
      @onClear="onClear" :cameraId="$store.state.selectedCameraDeviceId">
    </scan-roll>
    <div class="roll-info">
      <div v-if="code != ''"><img src="../assets/shtrihcode32.png" /> <strong> {{ code }} </strong></div>
      <div v-if="inRequesting">Поиск ролика...</div>
      <div v-if="error_text != ''"> <strong>{{ error_text }}</strong></div>

      <div class="grid-wrapper" v-if="!inRequesting && roll_code != ''">
        <div class="rollinfo_grid">

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <strong>{{ roll.gr }} г/м2</strong>
          </div>

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <strong>{{ roll.gr }} мм</strong>
          </div>

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <strong>{{ roll.count_kg }} кг</strong>
          </div>

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <strong>№ {{ roll.roll_number }}</strong>
            <span class="red_text" v-if="roll.nekond != ''"><strong>{{ roll.nekond }}</strong></span>
          </div>

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <strong>{{ roll.marka }}</strong>
          </div>

          <div class="rollinfo_grid_item rollinfo_grid_item_value">
            <button class="btn btn-warning" @click="addRollToCart(roll)">
              <img src="../assets/addcart24.png" />
            </button>

          </div>

        </div>
      </div>

    </div>

  </div>

  <div class="bordered-div" id="skalNumPanel" style="margin: 5px">
    <label for="inputAssemblyName">Название: </label>
    <input id="inputAssemblyName" v-model="assemblyName" @input="assemblyNameOnChange" />
    &nbsp;
  </div>

  <div class="bordered-div" id="skalNumPanel" style="margin: 5px">
    <label for="assemblyType">Вид: </label>
    <select id="assemblyType" :value="assemblyType" @change="assemblyTypeOnChange">
      <option value="">Отгрузка</option>
      <option value="1">Собственные нужды</option>
      <option value="2">Списание</option>
    </select>
  </div>

  <div v-if="assemblyRolls.length > 0" class="assemblyrolls_block">
    <div>
      <button type="button" class="btn btn-warning buttons_action_assemblyrolls" data-bs-toggle="modal"
        data-bs-target="#confirmationClearAssemblyRolls">
        Очистить сборку
      </button>

      <button type="button" class="btn btn-warning buttons_action_assemblyrolls" data-bs-toggle="modal"
        data-bs-target="#confirmationPutAssemblyTo1C">
        Перенести сборку в 1С
      </button>

      <div>
        Итого роликов: {{ assemblyRolls.length }}, масса: {{ totalCount_kg }} кг
      </div>

    </div>
    <div class="assemblyrolls_grid_wrapper">
      <div class="assemblyrolls_grid">
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">Код</div>
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">№</div>
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">Гр.(ф)</div>
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">Гр.(эт.)</div>
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">Ф.</div>
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title">Масса</div>        
        <div class="assemblyrolls_grid_item assemblyrolls_grid_item_title"></div>

        <template v-for="roll in assemblyRolls" :key="roll.code">
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.code }}</div>
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.roll_number }} <span class="red_text"
              v-if="roll.nekond != ''"><strong>{{ roll.nekond }}</strong></span> </div>
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.gr }}</div>
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.gr2 }}</div>
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.format }}</div>
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">{{ roll.count_kg }}</div>          
          <div class="assemblyrolls_grid_item assemblyrolls_grid_item_value">
            <button class="btn btn-warning" @click="removeRollFromCart(roll)">
              <img src="../assets/remove24.png" />
            </button>
          </div>

        </template>

      </div>
    </div>
  </div>
</template>
  
<script>

import ScanRoll from "../components/ScanRoll.vue";
import { mapGetters } from 'vuex';
import { fetchPutAssemblyRollsTo1C } from '../api.js';
import store from '@/store';

export default {
  components: {
    ScanRoll,
  },

  data() {
    return {
      puttinTo1CinProgress: false,
      code: '',
      inRequesting: false,
      roll_code: '',
      error_text: '',
      roll: {},
      assemblyName: '',
      assemblyType: ''
    };
  },


  computed: {
    ...mapGetters(['assemblyRolls']),

    totalCount_kg() {
      return this.assemblyRolls.reduce((accum, item) => accum + item.count_kg, 0);
    },
  },

  methods: {

    onCode(code) {
      this.code = code;
    },

    onRoll(roll) {
      this.error_text = '';
      this.inRequesting = false;
      this.roll_code = roll.code;
      this.roll = roll;
    },

    onError(error_text) {
      this.error_text = error_text;
      this.inRequesting = false;
      this.roll_code = '';
      this.roll = {};
    },

    onRequesting() {
      this.inRequesting = true;
    },

    clearFoundedRoll() {
      this.error_text = '';
      this.inRequesting = false;
      this.roll_code = '';
      this.roll = {};
      this.code = '';
    },

    onClear() {
      this.clearFoundedRoll();
    },

    assemblyNameOnChange() {
      this.$store.dispatch("setAssemblyName", this.assemblyName);
    },

    assemblyTypeOnChange(e) {      
      this.assemblyType = e.target.value;
      this.$store.dispatch("setAssemblyType", this.assemblyType);
    },

    dateToStringDDMMYYYY(date) {
      if (date.length < 10) return "";

      return (
        date.substring(8, 10) +
        "." +
        date.substring(5, 7) +
        "." +
        date.substring(0, 4)
      );
    },

    addRollToCart(roll) {
      this.$store.dispatch('addRollToAssemblyRolls', roll);
      this.clearFoundedRoll();
    },

    removeRollFromCart(roll) {
      this.$store.dispatch('removeRollFromAssemblyRolls', roll);
    },

    clearAssemblyRolls() {
      this.$store.dispatch('clearAssemblyRolls');
      this.$store.dispatch('setAssemblyName', '');
      this.$store.dispatch('setAssemblyType', '');
      this.assemblyName = '';
      this.assemblyType = '';
    },

    async putAssemblyRollsTo1C() {

      this.puttinTo1CinProgress = true;
      const fetchResult = await fetchPutAssemblyRollsTo1C(
        this.$store.state.skladNum,
        this.assemblyName,
        this.assemblyType,
        this.assemblyRolls
      );

      if (fetchResult.bool_result == true) {
        this.puttinTo1CinProgress = false;
        await this.showAlert(
          `Создан документ сборки роликов №${fetchResult.doc_num}!`,
          "success"
        );
      } else {
        this.puttinTo1CinProgress = false;
        alert("Ошибка: " + fetchResult.errorText);
      }

      this.$store.dispatch('clearAssemblyRolls');
      this.$store.dispatch('setAssemblyName', '');
      this.$store.dispatch('setAssemblyType', '');
      this.assemblyName = '';
      this.assemblyType = '';
    },

    async showAlert(message, type) {
      const alertPlaceholder = document.getElementById("liveAlertPlaceholder");

      const wrapper = document.createElement("div");
      wrapper.innerHTML = [
        `<div class="alert alert-${type} alert-dismissible" role="alert">`,
        `   <div>${message}</div>`,
        '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
        "</div>",
      ].join("");

      alertPlaceholder.append(wrapper);
    },

  },

  mounted() {
    this.assemblyName = this.$store.state.assemblyName;
    this.assemblyType = this.$store.state.assemblyType;
  },
};
</script>  

<style scoped>
.scan-and-info {
  display: grid;
  grid-template-columns: 5fr 2fr;
}

.roll-info {
  margin: 10px;
}

.scan-roll-wrapper {
  margin: 0 auto;
}

.grid-wrapper {
  margin: 5px;
}

.assemblyrolls_grid_wrapper {
  margin: 0 5px;
}

.rollinfo_grid {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 300px;
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: lightgrey;
}

.rollinfo_grid_item {
  padding: 5px;
  border-color: lightgrey;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.rollinfo_grid_item_value {
  text-align: center;
}

.assemblyrolls_grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-color: lightgrey;
  border-top: 1px solid;
  border-left: 1px solid;
}

.assemblyrolls_grid_item_title {
  font-weight: bold;
  font-size: smaller;
}

.assemblyrolls_grid_item {
  padding: 5px;
  border-color: lightgrey;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.assemblyrolls_grid_item_value {
  text-align: center;
  font-size: smaller;
}

.buttons_action_assemblyrolls {
  margin: 5px;
}

.div-title {
  font-size: large;
  padding: 5px;
  background-color: darkseagreen;
  margin-bottom: 5px;
}

.red_text {
  color: red;
}
</style>